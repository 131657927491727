<template>
  <v-container fluid fill-height class="d-flex justify-start align-center flex-column pa-0">
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <migration-logo />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-4">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-3 mb-3 rounded-xl " rounded :class="$style.form">
            <h2 class="text-center mt-5" style="color: red;">
              切替操作エラー
            </h2>

            <v-card-text :class="$style.form_text">
              <h2 :class="$style.error_msg">
                エラーコード：{{ errorCode }}
              </h2>
              <h2 :class="$style.form_msg">
                JRE IDへの切替操作でエラーが<br>
                発生しました。<br>
                お手数ですが、下のリンクから<br>
                再度切替手続きを開始してください。
              </h2>
              <base-btn :label="buttonName" style="font-size: 13px;font-weight: 600; background-color: #00b050"
                @click="onRedirect" :style="{ color: 'white', margin: '20px 0px' }" />

              <div class="pb-12">
                <h2 :class="$style.contact_msg">
                  切替操作が成功しない場合<br>
                  <a href="https://jam-id.jp/faq/index.html" target="_blank">こちら</a>をご確認ください
                </h2>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </v-container>
</template>


<script>
export default {
  props: {
    detailCode: String,
  },
  data() {
    return {
      buttonName: 'アカウント切替手続き画面TOP',
      title: "アカウント切替手続き画面",
    };
  },
  computed: {
    errorCode() {
      return this.detailCode || "";
    },
  },
  methods: {
    onRedirect() {
      this.$router.push({ name: "login-confirm" });
    }
  },
}
</script>

<style module lang="scss">
@import "@/styles/common.scss";


.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form_msg {
  font-size: 16px !important;
  color: #373e42;
  font-weight: 600;
  line-height: 1.8rem;
  text-indent: 10px;
  margin: 10px -5px;
  text-align: center !important;
}

.contact_msg {
  font-size: 15px !important;
  color: #373e42;
  font-weight: 6000;
  line-height: 1.8rem;
  margin-top: 30px;
  text-align: center;
}

.error_msg {
  font-size: 16px !important;
  color: #373e42;
  font-weight: 600;
  line-height: 1.8rem;
  margin-bottom: 20px
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}
</style>
